<template>
  <div v-if="dataPoliticas.length > 0 && dataPoliticas[tabIndex]">
    <header-page-derek
      :title="dataPoliticas[tabIndex].titulo"
      :description="''"
      :skeleton="false"
    />
    <div class="container-polices container-max-width">
      <div class="col-12 col-md-4 py-4 col-xl-3 options-polices">
        <div v-for="(politica, index) in dataPoliticas" :key="index">
          <div
            v-if="politica.tipo_pagina == 0"
            class="py-2 px-4 w-100 text-center"
            @click="
              tabIndex = index
              isMovilScroll()
            "
          >
            <router-link
              :to="{ name: 'Politicas', params: { slug: politica.slug } }"
            >
              <div
                class="btn-option-police"
                :class="tabIndex == index ? 'btn-option-police-selected' : ''"
              >
                {{ politica.titulo }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="dataPoliticas.length"
        class="col-12 col-md-8 col-xl-9 html-politics"
        v-html="dataPoliticas[tabIndex].descripcion"
      />
    </div>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'
export default {
  components: { HeaderPageDerek },
  data() {
    return {
      optionSelect: 0,
      dataPoliticas: [],
      tabIndex: 0,
    }
  },
  watch: {
    $route() {
      let index = 0
      this.dataPoliticas.find(e => {
        if (e.slug == this.$route.params.slug) {
          this.tabIndex = index
        } else {
          index++
        }
      })
      document.title =
        this.dataPoliticas[this.tabIndex].titulo + ' | DEREK Tienda Online'
      // this.$gtag.pageview(this.$route.fullPath)
    },
  },
  async mounted() {
    document.title = this.title + ' | DEREK Tienda Online'
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: 'layouts/get_footer',
    })
      .then(response => {
        this.dataPoliticas = response.data.paginas
        let index = 0
        response.data.paginas.find(e => {
          if (e.slug == this.$route.params.slug) {
            this.tabIndex = index
            document.title =
              this.dataPoliticas[this.tabIndex].titulo +
              ' | DEREK Tienda Online'
          } else {
            index++
          }
        })
        window.scrollTo(0, 0)
      })
      .catch(error => {
        console.log(error)
      })
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    isMovilScroll() {
      if (window.screen.width < 768) {
        window.scrollTo(0, 587)
      }
    },
  },
}
</script>
